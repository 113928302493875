import message from 'antd/lib/message'
import { mutate } from 'swr'
import {
  createProject,
  createProject_endpoint,
  getProjectSettings_endpoint,
  getProjects_endpoint,
  getProject_endpoint,
  getSitesByProjectId_endpoint,
  getSites_endpoint,
  updateProject,
  updateProjectMailTemplates,
  updateProjectSettings,
} from '../../service/api-gateway'

export const updateProjectSubmit = (projectId: string, data: any) => {
  console.log(' UpdateProject > data = ', data)
  return updateProject(projectId, data)
    .then((result) => {
      mutate(getProjects_endpoint)
      mutate(getProjectSettings_endpoint(projectId || ''))
      mutate(getProject_endpoint(projectId || ''))
      console.log('result = ', result)
      return result
    })
    .catch((error) => {
      console.log('error = ', error)
      return Promise.reject(error)
    })
}

export const createProjectSubmit = (data: any) => {
  console.log(' CreateProject > data = ', data)
  return createProject(data)
    .then((result) => {
      mutate(createProject_endpoint)
      console.log('result = ', result)
      return Promise.resolve(result)
    })
    .catch((error) => {
      console.log('error = ', error)
      return Promise.reject(error)
    })
}

export const updateProjectSettingsSubmit = (projectId: string, data: any) => {
  return updateProjectSettings(projectId, data)
    .then((result) => {
      message.success('Project settings updated')
      mutate(getProjects_endpoint)
      mutate(getSites_endpoint)
      mutate(getProject_endpoint(projectId || ''))
      mutate(getProjectSettings_endpoint(projectId || ''))
      mutate(getSitesByProjectId_endpoint(projectId || ''))
      console.log('result = ', result)
      return Promise.resolve(result)
    })
    .catch((error) => {
      console.log('error = ', error)
      message.error('Project settings not updated')
      return Promise.reject(error)
    })
}

export const updateProjectMailTemplatesSubmit = (projectId: string, data: any) => {
  return updateProjectMailTemplates(projectId, data)
    .then((result) => {
      mutate(getProjects_endpoint)
      mutate(getProject_endpoint(projectId || ''))
      console.log('result = ', result)
      return Promise.resolve(result)
    })
    .catch((error) => {
      console.log('error = ', error)
      message.error('Project settings not updated')
      return Promise.reject(error)
    })
}
