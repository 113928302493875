export const projectForm = [
  {
    id: 'projectRef',
    type: 'TEXT',
    placeholder: 'Enter project id',
    label: 'Global project identifier',
    prefix: '',
    postfix: '',
    defaultValue: '',
    value: '',
    className: 'col-12',
    validation: { required: true },
    errorMsg: 'Project ref is required',
  },
  {
    id: 'title',
    type: 'TEXT',
    placeholder: 'Give your project a title',
    label: 'Title',
    prefix: '',
    postfix: '',
    defaultValue: '',
    value: '',
    className: 'col-12',
    validation: { required: true },
    errorMsg: 'Project title is required',
  },
  {
    id: 'languages',
    type: 'SELECT_MULTIPLE',
    placeholder: 'Select language',
    label: 'Language',
    prefix: '',
    postfix: '',
    defaultValue: '',
    value: '',
    className: 'col-12',
    validation: { required: true },
    errorMsg: 'A language must be selected',
  },
  {
    id: 'condition',
    type: 'DROPDOWN',
    placeholder: 'Select a condition',
    label: 'Condition',
    options: ['Eczcema', 'Arthritis', 'Psoriasis', 'Acne', 'Actinic keratosis'],
    prefix: '',
    postfix: '',
    className: 'col-12',
    validation: { required: true },
    errorMsg: 'A condition must be selected',
    value: '',
  },
  {
    id: 'age',
    type: 'SLIDER_RANGE',
    placeholder: 'Select age range',
    label: 'Age range:',
    prefix: '',
    postfix: '',
    options: [20, 80],
    defaultValue: '',
    className: 'col-12',
    validation: { required: true },
    errorMsg: 'Age must be selected',
  },
  {
    id: 'maxDistance',
    type: 'SLIDER',
    placeholder: 'Select max distance',
    label: 'Maximum distance from site to participant (km):',
    prefix: '',
    postfix: '',
    displayFactor: (v) => {
      return Math.round(v / 1000)
    },
    min: 1 * 1000,
    max: 100 * 1000,
    defaultValue: '',
    className: 'col-6',
    validation: { required: true },
    errorMsg: 'Maximum distance must be selected',
  },
  {
    id: 'maxDistanceToTravel',
    type: 'SLIDER',
    placeholder: 'Select max distance',
    label: 'Max distance a user will be asked to travel (km):',
    displayFactor: (v) => {
      return Math.round(v / 1000)
    },
    min: 100 * 1000,
    max: 800 * 1000,
    prefix: '',
    postfix: '',
    defaultValue: '',
    className: 'col-6',
    validation: { required: true },
    errorMsg: 'Max travel distance must be selected',
  },
]

export const defaults = {
  age: [20, 80],
  active: false,
  languages: [],
  maxDistance: 150000,
  maxDistanceToTravel: 550000,
}
