/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 08/04/2022.
 */
import React from 'react'
import { Switch } from 'antd'
import { Controller } from 'react-hook-form'

const FormSwitch = ({ data, formHook }) => {
  const { control } = formHook

  return (
    <div className="d-flex justify-content-between mt-2">
      <p>{data.label}</p>
      <Controller
        name={data.id}
        control={control}
        render={({ field: { onChange, value } }) => {
          return <Switch checked={value} onChange={onChange} />
        }}
      />
    </div>
  )
}
export default FormSwitch
