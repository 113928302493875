import React, { useEffect, useState } from 'react'

import { authStore } from './store/auth/authStore'
import { SpinnerFullHeight } from './components/spinner/SpinnerFullHeight'
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorView } from './components/error/ErrorView'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { LoginPage } from './pages/login/LoginPage'
import { Main } from './Main'
import { UnsubscribeSuccess } from './pages/unsubscribe/UnsubscribeSuccess'

function App() {
  const user: any = authStore((state) => state.user)
  const init = authStore((state) => state.init)
  const [error, setError] = useState(null)

  console.log(' App > user = ', user)

  useEffect(() => {
    if (!user) {
      console.log(' App > run = ')
      init().catch((error: any) => {
        console.log(' App > init = ', error)
        setError(error)
      })
    }
  }, [init, user])

  if (!user && !error) {
    return <SpinnerFullHeight></SpinnerFullHeight>
  }

  const shouldRedirect = !user || user.challengeName

  return (
    <BrowserRouter>
      <ErrorBoundary FallbackComponent={ErrorView}>
        <Routes>
          <Route path="/login/*" element={<LoginPage />} />
          <Route
            path="/unsubscribe/*"
            element={
              <Routes>
                <Route path="/:token" element={<UnsubscribeSuccess />} />
              </Routes>
            }
          />
          <Route path="/*" element={shouldRedirect ? <Navigate replace to="/login" /> : <Main />} />
        </Routes>
      </ErrorBoundary>
    </BrowserRouter>
  )
}

export default App
