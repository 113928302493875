import { UseFormReturn, FieldValues } from 'react-hook-form'
import {
  CheckboxQuestion,
  InfoQuestion,
  NumberQuestion,
  Question,
  QuestionType,
  RadioQuestion,
  TextQuestion,
  NameQuestion,
  BmiQuestion,
  PhoneNumberQuestion,
  GenderQuestion,
  BirthdateQuestion,
} from '../../Types'

import { QuestionCreateFormProps } from './QuestionCreateForm'
import RadioCreateForm from './RadioCreateForm'
import TextCreateForm from './TextCreateForm'
import CheckboxCreateForm from './CheckboxCreateForm'
import InfoCreateForm from './InfoCreateForm'
import NumberCreateForm from './NumberCreateForm'
import BmiCreateForm from './BmiCreateForm'
import PhoneNumberCreateForm from './PhoneNumberCreateForm'
import NameCreateForm from './NameCreateForm'
import GenderCreateForm from './GenderCreateForm'
import BirthdateCreateForm from './BirthdateCreateForm'

export type TypedQuestionCreateFormProps<T extends Question> = QuestionCreateFormProps & {
  type: QuestionType
  formHook: UseFormReturn<FieldValues, any>
  defaultFormFieldConfig: any
  question?: T
}

export const TypeSpecificCreateForm = (props: TypedQuestionCreateFormProps<Question>) => {
  const typeSpecificFormFragment = {
    radio: <RadioCreateForm {...(props as TypedQuestionCreateFormProps<RadioQuestion>)} />,
    text: <TextCreateForm {...(props as TypedQuestionCreateFormProps<TextQuestion>)} />,
    checkbox: <CheckboxCreateForm {...(props as TypedQuestionCreateFormProps<CheckboxQuestion>)} />,
    number: <NumberCreateForm {...(props as TypedQuestionCreateFormProps<NumberQuestion>)} />,
    info: <InfoCreateForm {...(props as TypedQuestionCreateFormProps<InfoQuestion>)} />,
    bmi: <BmiCreateForm {...(props as TypedQuestionCreateFormProps<BmiQuestion>)} />,
    phonenumber: <PhoneNumberCreateForm {...(props as TypedQuestionCreateFormProps<PhoneNumberQuestion>)} />,
    name: <NameCreateForm {...(props as TypedQuestionCreateFormProps<NameQuestion>)} />,
    gender: <GenderCreateForm {...(props as TypedQuestionCreateFormProps<GenderQuestion>)} />,
    birthdate: <BirthdateCreateForm {...(props as TypedQuestionCreateFormProps<BirthdateQuestion>)} />,
  }

  return typeSpecificFormFragment[props.type]
}

export default TypeSpecificCreateForm
