import { getReq } from '../service/API'
import useSWR from 'swr'
import { BASE_URL } from '../constants'
//import { errorStore } from '../store/ErrorStore'

export const useMe = () => {
  const p = BASE_URL + `/api/me`

  //const cacheKey = formId ? p : null
  const { data, error } = useSWR(p, getReq, { suspense: true })

  /*if (error) {
		setError(error.error)
	}*/

  return {
    data: !data ? data : data,
    isLoading: !error && !data,
    isError: error,
  }
}
