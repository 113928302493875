/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 06/12/2021.
 */
import { useMe } from '../../hooks/useMe'
import { GroupHeader } from '../../components/display/GroupHeader'
import InputBox from '../../components/form/InputBox'
import { ReactComponent as SearchIcon } from '../../assets/search.svg'

export const FrontPage = () => {
  const { data } = useMe()

  console.log(' FrontPage > data = ', data)

  return (
    <div className="container ">
      <div className="row  ">
        <div className="col-12 my-4">
          <div className="d-flex justify-content-center mt-3">
            <div
              className="ps-3 py-1 w-50"
              style={{
                background: '#FFFFFF',
                border: '1px solid #dfdfdf',
                boxShadow: '0px 5px 16px -2px rgba(88, 88, 88, 0.2)',
                borderRadius: 50,
              }}
            >
              <SearchIcon className="" style={{ height: '1.5rem' }}></SearchIcon>
              <InputBox className="py-2 ms-3 " enterKeyDown={() => {}} inputChangeFunc={() => {}}></InputBox>
            </div>
          </div>
        </div>

        <div className="col-12 mb-2 mt-4">
          <div className="group-item ">
            <GroupHeader label="Latest updates" className="ps-3 "></GroupHeader>
            <div className="p-3">
              <p>test </p>
              <p>test </p>
              <p>test </p>
              <p>test </p>
              <p>test </p>
            </div>
          </div>
        </div>

        <div className="col-6 my-2">
          <div className="group-item ">
            <GroupHeader label="Latest updates" className="ps-3  "></GroupHeader>
            <div className="p-3">
              <p>test </p>
              <p>test </p>
              <p>test </p>
              <p>test </p>
              <p>test </p>
            </div>
          </div>
        </div>

        <div className="col-6 my-2">
          <div className="group-item ">
            <GroupHeader label="Latest updates" className="ps-3 "></GroupHeader>
            <div className="p-3">
              <p>test </p>
              <p>test </p>
              <p>test </p>
              <p>test </p>
              <p>test </p>
            </div>
          </div>
        </div>

        {/*<GroupHeader label="New project" className="col-6"></GroupHeader>*/}
      </div>
    </div>
  )
}
