/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 01/10/2021.
 */
import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import { COLOR_PRIMARY, PAGES, REACT_APP_ENV } from '../../constants'
import { authStore } from '../../store/auth/authStore'
import { Button, Dropdown, Menu } from 'antd'
import { DownOutlined } from '@ant-design/icons'

export const Header = () => {
  let navStyle = {
    color: 'rgba(0,0,0,.5)',
    fontSize: '.9rem',
    borderBottom: '2px solid white',
  }

  let activeStyle = {
    ...navStyle,
    color: 'black',
    borderBottom: '2px solid ' + COLOR_PRIMARY,
  }

  return (
    <div className="container-fluid bg-white" style={{ boxShadow: '0px -5px 10px 7px rgba(0, 0, 0, 0.05)' }}>
      {REACT_APP_ENV !== 'production' ? (
        <div className="row" style={{ background: '#068bd3' }}>
          <div className="col-12 d-flex justify-content-center">
            <p className="py-2 p-small text-white">{REACT_APP_ENV} environment</p>
          </div>
        </div>
      ) : null}
      <div className="row " style={{}}>
        <div className="col-12 col-md-3 pb-0 d-flex justify-content-center justify-content-md-start align-items-center">
          <Link to="/" className="mb-0 text-decoration-none">
            <h5 className="mb-0 fw-bold">Recruitment Admin</h5>
          </Link>
        </div>

        <div className="col-12 col-md-6 d-flex justify-content-center align-items-center">
          {PAGES.map((item, index) => {
            return (
              <NavLink
                to={item.to}
                key={index}
                className="mx-3 text-decoration-none py-3"
                style={({ isActive }) => {
                  return isActive ? activeStyle : navStyle
                }}
              >
                <p className="font-weight-bold">{item.title}</p>
              </NavLink>
            )
          })}
        </div>

        <div className="col-12 col-md-3 d-flex justify-content-end align-items-center">
          <a
            className="me-3 text-black opacity-75"
            href="https://recruitment-dashboard.studiesandme.com/"
            target="_blank"
            rel="noreferrer"
          >
            <Button size="middle">
              <p className="p-small">Open dashboard</p>
            </Button>
          </a>
          <SettingsMenu></SettingsMenu>
        </div>
      </div>
    </div>
  )
}

const SettingsMenu = () => {
  const user = authStore((state) => state.user)

  const name = user?.attributes.email || ''
  const letter = user?.attributes.email ? user?.attributes.email[0] : ''

  const logout = authStore((state) => state.logout)

  const loguserOut = () => {
    logout()
  }

  const menu = (
    <Menu>
      <Menu.Item key={0} disabled={true}>
        <p style={{ minWidth: 100 }}>{name}</p>
      </Menu.Item>
      <Menu.Item key={1}>
        <p onClick={loguserOut} style={{ width: 100 }}>
          Logout
        </p>
      </Menu.Item>
    </Menu>
  )

  const size = 34
  return (
    <Dropdown overlay={menu} placement="bottomRight" arrow>
      <div className="d-flex pointer mr-2">
        <div className="rounded-circle bg-primary me-2" style={{ width: size, height: size }}>
          <p className="d-flex justify-content-center align-items-center h-100 fw-bold text-white">
            {letter.toUpperCase()}
          </p>
        </div>
        <div className="mt-1 ">
          <DownOutlined />
        </div>
      </div>

      {/*<a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
        Hover me <DownOutlined />
      </a>*/}
    </Dropdown>
  )
}
