import Button from 'antd/lib/button'
import { useForm } from 'react-hook-form'
import QuestionCreateForm from '../../components/question-create-forms/QuestionCreateForm'
import QuestionEditForm from '../../components/question-create-forms/QuestionEditForm'
import { LanguageISOCode, Question } from '../../Types'

export const QuestionEditDrawer = (props: {
  closeDrawer: () => void
  handleSubmitQuestion: (question: any) => void
  question?: Question
  languages: LanguageISOCode[]
}) => {
  const formHook = useForm({ defaultValues: props.question }) as any
  const { handleSubmit } = formHook

  const onSubmit = (data: any, e: any) => {
    e.preventDefault()
    console.log(' QuestionEditDrawer > create = ', data)
    props.handleSubmitQuestion(data)
  }

  const onError = (err: any, e: any) => {
    e.preventDefault()
    console.log(' QuestionEditDrawer > onError = ', err)
  }

  return (
    <>
      <form className="row" onSubmit={handleSubmit(onSubmit, onError)}>
        {props.question === undefined || props.question === null ? (
          <QuestionCreateForm formHook={formHook} languages={props.languages} />
        ) : (
          <QuestionEditForm question={props.question!} formHook={formHook} languages={props.languages} />
        )}
        <div className="col-12 d-flex justify-content-center mt-5">
          <Button className="w-100" onClick={handleSubmit(onSubmit, onError)} type="primary" size="large">
            <strong>
              {props.question === undefined || props.question === null ? <>Create Question</> : <>Finish and Save</>}
            </strong>
          </Button>
        </div>
      </form>
    </>
  )
}
