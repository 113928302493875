/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 06/08/2022.
 */
import React from 'react'
import { AnswerLookup } from './AnswerLookup'
import { SignupUserTest } from './SignupUserTest'

export const TestingPage = () => {
  return (
    <>
      <div className="container">
        <div className="row" style={{}}>
          <div className="col-12 mt-4">
            <h4 className="fw-bold">This page is just for testing</h4>
            <hr />
          </div>
        </div>

        <AnswerLookup />

        <div className="row" style={{}}>
          <div className="col-12">
            <hr />
          </div>
        </div>
        <SignupUserTest></SignupUserTest>
      </div>
    </>
  )
}
