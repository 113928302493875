import { Alert, Button } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { ProjectMailTemplateFields } from './ProjectMailTemplateFields'
import { updateProjectMailTemplatesSubmit } from './ProjectsAPI'

type Props = {
  retententionArr: any[]
  projectLanguages: string[]
  closeDrawer: () => void
  mailSender: string
}

export const ProjectMailTemplateDrawer = ({ retententionArr, projectLanguages, closeDrawer, mailSender }: Props) => {
  const { projectId, templateName } = useParams() as { projectId: string; templateName: string }

  const eligibleTemplateArr = retententionArr.filter((item) => item._id === templateName)
  const eligibleTemplate = eligibleTemplateArr[0]
  const templateTitle = eligibleTemplate.title
  const templateRef = eligibleTemplate.ref
  const templateTimestamp = eligibleTemplate.timestamp

  if (eligibleTemplateArr.length === 0) {
    alert('Template is missing')
  }
  const [errorState, setErrorState] = useState<any>()

  const formHook = useForm()
  const { handleSubmit, control } = formHook
  const { fields, append } = useFieldArray({
    name: 'form',
    control,
  } as { name: string; control: any })

  const buildDefaults = useCallback(() => {
    const assignDefaultValues: { subject: string; template: string; _id: string }[] = eligibleTemplate.template.map(
      (templateItem, index) => {
        const retentionLanguage: string = projectLanguages[index].toLowerCase()
        const temp = {
          subject: templateItem.subject || '',
          templateName: templateItem.templateName || '',
          templateLanguage: retentionLanguage,
          _id: templateItem._id,
        }
        return temp
      }
    )
    return assignDefaultValues
  }, [eligibleTemplate.template, projectLanguages])

  useEffect(() => {
    append(buildDefaults())
  }, [append, buildDefaults])

  const onSubmit = (data, e) => {
    e.preventDefault()

    let obj: any = []

    for (let i in data.form) {
      obj.push({
        subject: data.form[i].subject,
        templateName: data.form[i].templateName,
        templateLanguage: data.form[i].templateLanguage,
      })
    }

    const submitObj = {
      title: templateTitle,
      ref: templateRef,
      timestamp: templateTimestamp,
      template: obj,
    }

    updateProjectMailTemplatesSubmit(projectId, submitObj)
      .then(() => {
        closeDrawer()
      })
      .catch((error) => {
        setErrorState(error)
      })
  }

  const onError = (err, e) => {
    console.log(' ProjectPageCreate > onError = ', err)
    e.preventDefault()
  }

  return (
    <>
      <form className="row" onSubmit={handleSubmit(onSubmit, onError)}>
        <div className="col-12">
          {fields.map((item: any, index) => {
            //Controller is probably the cleaner solution here
            return (
              <div key={index}>
                <ProjectMailTemplateFields
                  templateTitle={templateTitle}
                  formHook={formHook}
                  index={index}
                  item={item}
                  projectId={projectId}
                  mailSender={mailSender}
                ></ProjectMailTemplateFields>
              </div>
            )
          })}
        </div>
        <div className="" style={{}}>
          <div className="col-12 d-flex justify-content-center mt-1 mb-3">
            <Button className="col-12" onClick={handleSubmit(onSubmit, onError)} type="primary">
              Save
            </Button>
          </div>
          {errorState ? (
            <Alert className="mt-3" showIcon message={errorState.type} description={errorState.message} type="error" />
          ) : (
            <></>
          )}
        </div>
      </form>
    </>
  )
}
