import { PhoneNumberQuestion } from '../../Types'
import { TypedQuestionCreateFormProps } from './TypeSpecificCreateForm'
// import { getTranslationAwareFormType } from '../translation-aware-form/translationAwareFormFactory'

export const PhoneNumberCreateForm = (props: TypedQuestionCreateFormProps<PhoneNumberQuestion>) => {
  // Still just conforms exactly to base question, but I'll leave this here
  // so we can just add things if we need
  console.debug('Phone number create form with', props) // mainly to make the linter happy

  return <></>
}

export default PhoneNumberCreateForm
