import { GroupHeader } from '../../components/display/GroupHeader'
import {
  DeleteOutlined,
  CopyOutlined,
  LineChartOutlined,
  DoubleRightOutlined,
  ApartmentOutlined,
  EditOutlined,
} from '@ant-design/icons'
import { Button, Switch } from 'antd'
import { Tooltip } from 'antd'
import { Link } from 'react-router-dom'
import {
  QUESTIONNAIRE_PAGE_STATS,
  QUESTIONNAIRE_PAGE_EDIT,
  QUESTIONNAIRE_PAGE_FLOW,
  QUESTIONNAIRE_PAGE_TEST,
} from '../../constants'
import { post } from '../../service/API'
import {
  createQuestionnaire_endpoint,
  deleteQuestionnaire,
  getProjectSettings_endpoint,
  getProject_endpoint,
  updateQuestionnaire,
  duplicateQuestionnaire_endpoint,
} from '../../service/api-gateway'
import { mutate } from 'swr'
import { Questionnaire } from '../../Types'

const ProjectQuestionnaire = ({ project }) => {
  const handleCreateClick = () => {
    const name = prompt('Please enter a descriptive name for the questionnaire!')

    post(createQuestionnaire_endpoint, { projectId: project._id, name: name })
      .then((_) => mutate(getProject_endpoint(project._id)))
      .catch((err) => alert(err.message))
  }

  const handleDuplicateClick = (questionnaireId: string) => () => {
    const name = prompt('Please enter a descriptive name for the questionnaire!')

    post(duplicateQuestionnaire_endpoint(questionnaireId), { name: name })
      .then((_) => mutate(getProject_endpoint(project._id)))
      .catch((err) => alert(err.message))
  }

  const activateQuestionnaire = (questionnaireId: string) => (activate: boolean) => {
    // Need to activate first since we can't deactivate last questionnaire
    if (activate) {
      let questionnaire = project.questionnaires.filter((q: Questionnaire) => q._id === questionnaireId)[0]

      updateQuestionnaire(questionnaire._id, {
        ...questionnaire,
        active: true,
      })
        .then(() =>
          Promise.all(
            project.questionnaires
              .filter((q: Questionnaire) => q._id !== questionnaireId)
              .map((q: Questionnaire) => {
                let newQuestionnaire = {
                  ...q,
                  active: false,
                }

                return updateQuestionnaire(q._id, newQuestionnaire)
              })
          )
        )
        .then(() => {
          mutate(getProject_endpoint(project._id))
          mutate(getProjectSettings_endpoint(project._id))
        })
        .catch((err) => alert(err.message))
    } else {
      Promise.all(
        project.questionnaires.map((q: Questionnaire) => {
          let isActive = q._id === questionnaireId && activate
          let newQuestionnaire = {
            ...q,
            active: isActive,
          }

          return updateQuestionnaire(q._id, newQuestionnaire)
        })
      )
        .then(() => {
          mutate(getProject_endpoint(project._id))
          mutate(getProjectSettings_endpoint(project._id))
        })
        .catch((err) => alert(err.message))
    }
  }

  const handleDeleteClick = (questionnaireId: string) => {
    const input = prompt(
      'You are about to permanently delete this questionnaire and ALL related data including questions, answers and enrollments. Are you absolutely sure this is what you want? type "confirm" below to complete this action, this cannot be undone!'
    )

    if (input?.toLowerCase() !== 'confirm') return

    deleteQuestionnaire(questionnaireId)
      .then((_: any) => mutate(getProject_endpoint(project._id)))
      .catch((error) => {
        console.error(error)
        alert(error.message)
      })
  }

  return (
    <GroupHeader
      label="Questionnaire"
      header={
        <Button type="primary" onClick={handleCreateClick}>
          Create Questionnaire
        </Button>
      }
    >
      <div className="col-12 mb-3 bg-white mt-3">
        {project.questionnaires.map((questionnaire: Questionnaire, index: number) => (
          <div key={index} className="mb-2 py-3 px-3 d-flex justify-content-between border align-items-center">
            <div className="d-flex align-items-center">
              <Tooltip overlay="Activate/Deactivate Questionnaire">
                <Switch checked={questionnaire.active === true} onChange={activateQuestionnaire(questionnaire._id)} />
              </Tooltip>
              <div className="">
                <p className="mx-3 p-mini opacity-50 mb-0">{questionnaire._id}</p>
                <p className="mx-3">{questionnaire.name}</p>
              </div>

              {!questionnaire.active ? (
                <div className="mr-3 px-1 pointer d-flex flex-column justify-content-center">
                  <Tooltip overlay="Delete Questionnaire">
                    <Button
                      danger
                      onClick={(e) => handleDeleteClick(questionnaire._id)}
                      style={{
                        border: 'none',
                        padding: '0',
                        marginBottom: '2px',
                      }}
                    >
                      <DeleteOutlined style={{ fontSize: '1.5rem' }} />
                    </Button>
                  </Tooltip>
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className="d-flex">
              {questionnaire.questions.length > 0 && questionnaire.end && questionnaire.start ? (
                <>
                  <div className="mr-3 px-3 pointer">
                    <Tooltip overlay="Duplicate This Questionnaire">
                      <Link onClick={handleDuplicateClick(questionnaire._id)} to=".">
                        <CopyOutlined style={{ fontSize: '1.5rem' }} />
                      </Link>
                    </Tooltip>
                  </div>

                  <div className="mr-3 px-3 pointer">
                    <Tooltip overlay="Questionnaire Statistics">
                      <Link
                        to={QUESTIONNAIRE_PAGE_STATS.to
                          .replace(':projectId', project._id)
                          .replace(':questionnaireId', questionnaire._id)}
                      >
                        <LineChartOutlined style={{ fontSize: '1.5rem' }} />
                      </Link>
                    </Tooltip>
                  </div>

                  <div className="mr-3 px-3 pointer">
                    <Tooltip overlay="Test Questionnaire">
                      <Link
                        to={QUESTIONNAIRE_PAGE_TEST.to
                          .replace(':questionnaireId', questionnaire._id)
                          .replace(':language', project.languages[0])}
                      >
                        <DoubleRightOutlined style={{ fontSize: '1.5rem' }} />
                      </Link>
                    </Tooltip>
                  </div>
                </>
              ) : (
                <></>
              )}

              {questionnaire.active === true ? (
                <></>
              ) : (
                <div className="mr-3 px-3 pointer">
                  <Tooltip overlay="Edit Question Order">
                    <Link
                      to={QUESTIONNAIRE_PAGE_FLOW.to
                        .replace(':projectId', project._id)
                        .replace(':questionnaireId', questionnaire._id)}
                    >
                      <ApartmentOutlined style={{ fontSize: '1.5rem' }} />
                    </Link>
                  </Tooltip>
                </div>
              )}

              <div className="mr-3 px-1 pointer">
                <Tooltip overlay="Edit Questions">
                  <Link
                    to={QUESTIONNAIRE_PAGE_EDIT.to
                      .replace(':projectId', project._id)
                      .replace(':questionnaireId', questionnaire._id)}
                  >
                    <EditOutlined style={{ fontSize: '1.5rem' }} />
                  </Link>
                </Tooltip>
              </div>
            </div>
          </div>
        ))}
      </div>
    </GroupHeader>
  )
}
export default ProjectQuestionnaire
